
//import { SearchAPI } from "../search-api/search-api.js";
import { WPUtils } from "../wp-utils.js";
import { callRemoveBorders, getView } from "../common/js/a-zlist.js";
import { faqAccordion } from "../common/js/faq.js";
import $ from "jQuery";
// import "jquery-ui/ui/widgets/autocomplete";
import { freeTrialsCore } from "./free-trials-core.js";
const wpUtils = new WPUtils();
//let searchAPI;

function editModeCheck() {
  console.debug("EDIT MODE :", wpUtils.inAemEdit());
  if (wpUtils.inAemEdit()) {
    return;
  }
  init();
}

function init() {
 //  searchAPI = new SearchAPI();

  freeTrialsCore();

  $('.wp-card-content').equalHeights();

  $(window).on("resize", function () { $('.wp-card-content').equalHeights(); });

  /*********  A-Z List   *********/
  window.onresize = function () {
    callRemoveBorders();

    if (getView() != 0) {
      resizeTrialBannerItems();
    } else {
      document.querySelectorAll(".wp-trial-banner-items .wp-item").forEach(function (e) {
        e.removeAttribute("style");
      });
    }
  }

  /*********  Top-Panel - Same size for trial banners cards   *********/
  let resizeTrialBannerItems = function () {
    let height = 0;
    document.querySelectorAll(".wp-trial-banner-items .wp-item").forEach(function (e) {
      e.removeAttribute("style");
      if (e.offsetHeight > height) height = e.offsetHeight;
    });

    document.querySelectorAll(".wp-trial-banner-items .wp-item").forEach(function (e) {
      e.style.height = height + "px";
    });
  };

  if (getView() != 0) resizeTrialBannerItems();

  /*********  Top-Panel - Make the cards clickable  *********/
  document.querySelectorAll('.lp-partners1 .wp-card').forEach(function (e) {
    e.onclick = function () {
      let url = e.querySelector("a:first-child").getAttribute('href');
      let target = e.querySelector("a:first-child").getAttribute('target');
      if (!target) {
        window.location.href = url;
      } else {
        window.open(url);
      }
    }
  });

  document.querySelectorAll('.lp-partners1 .wp-card a').forEach(function (e) {
    e.onclick = function (el) {
      el.preventDefault();
      let url = e.getAttribute('href');
      let target = e.getAttribute('target');

      if (target) {
        window.open(url);
        return false;
      } else {
        window.location.href = url;
      }
    }
  });

  faqAccordion();
}
editModeCheck();


