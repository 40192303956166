var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"text section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"skipLazyLoad") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"skipLazyLoad") : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":18,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"wp-product-img wp-clickable\"><img src=\"https://damassets.autodesk.net"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-banner") : stack1), depth0))
    + "\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"wp-product-img wp-clickable\"><img src=\"\" data-src=\"https://damassets.autodesk.net"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-banner") : stack1), depth0))
    + "\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"skipLazyLoad") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":28,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"skipLazyLoad") : stack1),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":31,"column":43}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                  <p class=\"wd-ma-0\"><img class=\"wp-product-logo wp-clickable\" src=\"https://damassets.autodesk.net"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-lockup") : stack1), depth0))
    + "\"></p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                  <p class=\"wd-ma-0\"><img class=\"wp-product-logo wp-clickable\" src=\"\" data-src=\"https://damassets.autodesk.net"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-lockup") : stack1), depth0))
    + "\"></p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"wp-product-desc\">\n                                <p class=\"wp-product-long-desc\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-desc") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                                <p class=\"wp-product-short-desc\"></p>\n                            </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"free-trial-link") : stack1), depth0))
    + "\" data-wat-content-type=\"waf-fst-try-2\" data-wat-trial-container=\"true\" data-wat-content-name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + "\" data-wat-trial-intent=\"true\" data-wat-trial-product-plc=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + "\" class=\"wp-free-trial-button\" ><span class=\"cmp-button__text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"free-trial-cta-link") : stack1), depth0))
    + "</span><svg class=\"wp-icon\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-arrow-button\"></use></svg></a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"wp-cta-container wp-card-reveal-cta wp-product-list\"><div class=\"wp-product-cta\"><a href=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-url") : stack1), depth0)) != null ? stack1 : "")
    + "\" data-wat-val=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":product list\"><svg class=\"wd-icon icon-hide-thick icon-svg-hide-thick\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-hide\"></use></svg><svg class=\"wd-icon hide icon-show-thick icon-svg-show-thick\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-show\"></use></svg></use></svg>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"card-reveal-cta") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"card-reveal-content") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":48,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-url") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":32},"end":{"line":51,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-2-url") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":55,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-3-url") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":36},"end":{"line":59,"column":39}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"wp-card-reveal-content\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"card-reveal-content") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"wp-cta-container wp-mt-0\"><div class=\"wp-product-cta\"><a href=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-url") : stack1), depth0)) != null ? stack1 : "")
    + "\" data-wat-val=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":product details\"><svg class=\"wd-icon icon-cta-go-arrow-circle icon-svg-cta-go-arrow-circle\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-cta-go-arrow-circle\"></use></svg>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-cta") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div></div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"wp-cta-container\"><div class=\"wp-product-cta\"><a href=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-2-url") : stack1), depth0)) != null ? stack1 : "")
    + "\" data-wat-val=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":product details\"><svg class=\"wd-icon icon-cta-go-arrow-circle icon-svg-cta-go-arrow-circle\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-cta-go-arrow-circle\"></use></svg>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-2-cta") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div></div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"wp-cta-container\"><div class=\"wp-product-cta\"><a href=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-3-url") : stack1), depth0)) != null ? stack1 : "")
    + "\" data-wat-val=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":product details\"><svg class=\"wd-icon icon-cta-go-arrow-circle icon-svg-cta-go-arrow-circle\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-cta-go-arrow-circle\"></use></svg>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-3-cta") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div></div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-url") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":33},"end":{"line":64,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-2-url") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":36},"end":{"line":68,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-3-url") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":36},"end":{"line":72,"column":39}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"wp-cta-container\"><div class=\"wp-product-cta\"><a href=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-url") : stack1), depth0)) != null ? stack1 : "")
    + "\" data-wat-val=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":product details\"><svg class=\"wd-icon icon-cta-go-arrow-circle icon-svg-cta-go-arrow-circle\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-cta-go-arrow-circle\"></use></svg>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-cta") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div></div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"wp-platform\"><span>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"platform-text") : stack1), depth0)) != null ? stack1 : "")
    + "</span></div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"wp-compare-before-price wd-font-14\">\n                                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-before") : stack1), depth0)) != null ? stack1 : "")
    + "\n                            </span>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"wp-compare-price-replacer wd-font-14\">\n                                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-replacer") : stack1), depth0)) != null ? stack1 : "")
    + "\n                            </span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "                             <div class=\"wp-hr\">&nbsp;</div>\n                            <div class=\"wp-pricing wp-discounted\"><span data-pds-target=\"pricing\" data-pds-purchase-type=\"BASIC\" data-pds-billing-plan=\"1-YEAR\" class=\"pds-replacer-done\">--</span>\n                            <span class=\"wp-price-plan\" data-wpd-string=\"card-price-plan\"> /year</span></div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"wp-compare-after-price wd-font-14\">\n                                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-after") : stack1), depth0)) != null ? stack1 : "")
    + "\n                            </span>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"subscribe-link") : stack1), depth0))
    + "\" class=\"wp-buy-button\" data-wat-trial-intent=\"true\" data-wat-val=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":subscribe\"><span class=\"cmp-button__text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"subscribe-cta-link") : stack1), depth0))
    + "</span> <svg class=\"wd-icon icon-arrow-button icon-svg-arrow-button\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-arrow-button\"></use></svg> </a>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <input type=\"checkbox\" value=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"location") || (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"location","hash":{},"data":data,"loc":{"start":{"line":108,"column":62},"end":{"line":108,"column":74}}}) : helper)))
    + "\" id=\"compare-plc-"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + "-"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"tid") : stack1), depth0))
    + "\" data-wat-link=\"true\" data-wat-val=\""
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":compare\" />\n                                <label for=\"compare-plc-"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + "-"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"tid") : stack1), depth0))
    + "\">Compare</label>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "                                <label class=\"compare-not-available\">Not available for compare</label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"decorative-box section\">\n    <div class=\"wp-product-card\">\n        <div class=\"box-content parsys\">\n            <div class=\"wp-on-sale-ribbon hide\">\n                <div class=\"wp-on-sale-ribbon-inner\">\n                    <svg><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-tag-promo-solid\"></use></svg>\n                    <span class=\"wp-on-sale-label\" data-wpd-string=\"card-on-sale-label\">Special Offer</span>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-banner") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"decorative-box section\">\n                <div class=\"wp-product-info-container\">\n                    <div class=\"box-content parsys\">\n                        <div class=\"text section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-lockup") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":32,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-desc") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":38,"column":35}}})) != null ? stack1 : "")
    + "                            <div class=\"wp-free-trial-button-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"free-trial-cta-link") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":28},"end":{"line":42,"column":35}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"card-reveal-cta") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":28},"end":{"line":60,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"card-reveal-cta") : stack1),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":73,"column":39}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"platform-text") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":28},"end":{"line":78,"column":35}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-before") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":28},"end":{"line":84,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-replacer") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":28},"end":{"line":89,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-replacer") : stack1),{"name":"unless","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":28},"end":{"line":94,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-after") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":28},"end":{"line":99,"column":35}}})) != null ? stack1 : "")
    + "                            <div class=\"wp-buy-button-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"subscribe-cta-link") : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":28},"end":{"line":103,"column":35}}})) != null ? stack1 : "")
    + "                            </div>\n                            <div class=\"wp-hr wp-hr-bottom\">&nbsp;</div>\n                            <div class=\"wp-compare-checkbox\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-enabled") : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":32},"end":{"line":110,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-enabled") : stack1),{"name":"unless","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":32},"end":{"line":113,"column":44}}})) != null ? stack1 : "")
    + "                            </div>\n                            \n                            \n                            \n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"decorative-box section\">\n                <div class=\"wp-card-control\">\n                    <div class=\"box-content parsys\">\n                        <div class=\"text section\">\n                            <div class=\"wp-expand\" data-wat-link=\"true\" data-wat-val=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":see more\"><svg class=\"wd-icon icon-show icon-svg-show\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-show\"></use></svg> <span data-wpd-string=\"card-see-more-label\">See more</span></div>\n                            <div class=\"wp-minimize\"><svg class=\"wd-icon icon-hide icon-svg-hide\"><use xlink:href=\"/content/dam/autodesk/icons.svg#icon-svg-hide\"></use></svg><span data-wpd-string=\"card-see-less-label\">See less</span></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});