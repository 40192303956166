var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <p class=\"wd-ma-0\">\n                                            <img class=\"wp-product-logo wp-clickable\" src=\"https://damassets.autodesk.net"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-lockup") : stack1), depth0))
    + "\">\n                                        </p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"text section\">\n                                            <p class=\"wd-font-14 wd-mb-0\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-long-description") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-desc") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":44},"end":{"line":31,"column":51}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"text section\">\n                                                <p class=\"wd-font-14 wd-mb-0\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-desc") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                                            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"wp-cta-container\">\n                                            <div class=\"wp-product-cta\">\n                                                <a href=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-url") : stack1), depth0)) != null ? stack1 : "")
    + "\" data-wat-loc=\"dct table\" data-wat-val=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":product details\">\n                                                    <svg id=\"icon-svg-arrow-stroke\" viewBox=\"0 0 20 20\" x=\"0px\" y=\"0px\" style=\"enable-background:new 0 0 20 20;\" xml:space=\"preserve\" width=\"100%\" height=\"100%\">\n	<g><path d=\"M10,20C4.5,20,0,15.5,0,10S4.5,0,10,0s10,4.5,10,10S15.5,20,10,20z M10,1.9c-4.5,0-8.1,3.6-8.1,8   S5.5,18,10,18c4.4,0,8.1-3.6,8.1-8.1C18,5.5,14.4,1.9,10,1.9z\"></path></g><polygon points=\"5.1,9.1 12.1,9.1 9.8,6.8 11,5.4 15.4,9.9 15.4,10.2 11,14.6 9.8,13.5 12.1,11 5.1,11 \"></polygon>\n	</svg><span>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-cta") : stack1), depth0)) != null ? stack1 : "")
    + "</span></a>\n                                            </div>\n                                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <span class=\"wp-compare-price-replacer\">\n                                                        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-replacer") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                                    </span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                                                <div class=\"wp-pricing wp-discounted\"><span data-pds-target=\"pricing\" data-pds-plc=\"\" data-pds-purchase-type=\"BASIC\" data-pds-billing-plan=\"1-YEAR\" class=\"wd-font-24 wd-color-gray-25 pds-replacer-done\">--</span>\n                                                <span class=\"wd-font-16 wp-price-plan\"> /year</span></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <span class=\"wp-compare-after-price\">\n                                                        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-after") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                                    </span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <ul class=\"wd-pl-20 wd-mt-8 wp-compare-use-for\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-use-for") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                            </ul>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <ul class=\"wd-pl-20 wd-mt-8 wp-compare-use-for\">\n                                                    "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-what-it-does") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                                </ul>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <p class=\"wd-mt-16 wp-compare-link\">\n                                                <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-link-url") : stack1), depth0))
    + "\" data-wat-loc=\"dct table\" >\n                                                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-link-cta") : stack1), depth0))
    + "\n                                                </a>\n                                                </p>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <ul class=\"wd-pl-20 wd-mt-8 wp-compare-use-for\">\n                                                    "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-includes") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                                </ul>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"heading section\">\n                                            <h2 class=\"wd-font-19 wd-uppercase wd-color-gray-25\">\n                                                Platform:\n                                            </h2>\n                                        </div>\n                                        <div class=\"text section\">\n                                            <span class=\"wd-font-12 wd-bold wd-uppercase wd-color-gray-60 wd-pr-4\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"platform-text") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n                                        </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-licensing") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"medium-6 grid-columns wp-free-trial-col\">\n                                                    <div class=\"responsive-column-0 responsive-column\">\n                                                        <div class=\"wd-pr-12 wd-pb-12\">\n                                                            <div class=\"column-content parsys\">\n                                                                <div class=\"button section\"><a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"free-trial-link") : stack1), depth0))
    + "\" class=\"wd-button wp-outline wd-font-14 wd-button-responsive wd-uppercase wp-compare-free-trial\" data-wat-val=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":free-trial\" data-wat-loc=\"dct table\" ><span class=\"cmp-button__text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"free-trial-cta-link") : stack1), depth0))
    + "</span><span class=\"wp-icon\">	<svg class=\"wd-icon icon-arrow-button icon-svg-arrow-button\"><use xlink:href=\"#icon-svg-arrow-button\"></use></svg>\n</span></a></div>\n                                                            </div>\n                                                        </div>\n                                                    </div>\n                                                </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"medium-6 grid-columns wp-subscribe-col\">\n                                                    <div class=\"responsive-column-0 responsive-column\">\n                                                        <div class=\"wd-pr-12 wd-pb-12\">\n                                                            <div class=\"column-content parsys\">\n                                                                <div class=\"button section\"><a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"subscribe-link") : stack1), depth0))
    + "\" class=\"wd-button wp-outline wd-font-14 wd-button-responsive wd-uppercase\" data-wat-val=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + ":subscribe\" data-wat-loc=\"dct table\" ><span class=\"cmp-button__text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"subscribe-cta-link") : stack1), depth0))
    + "</span> <span><svg class=\"wd-icon icon-arrow-button icon-svg-arrow-button\"><use xlink:href=\"#icon-svg-arrow-button\"></use></svg></span> </a></div>\n                                                            </div>\n                                                        </div>\n                                                    </div>\n                                                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-wat-dynamic-product=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"plc") : stack1), depth0))
    + "\" data-wat-dynamic-exposed=\"true\">\n    <div class=\"responsive-list-item-0 responsive-list-item\">\n        <div class=\"wd-pr-20 wd-mb-20\">\n            <div class=\"list-item-content parsys\">\n                <div class=\"referenced-content parbase section\">\n                    <div class=\"body-content parsys\">\n                        <div class=\"decorative-box section\">\n                            <div class=\"compare-card\" id=\"eagle\">\n                                <div class=\"box-content parsys\">\n                                    <div class=\"compare-block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"product-lockup") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":40},"end":{"line":17,"column":47}}})) != null ? stack1 : "")
    + "                                    </div>\n\n                                    <div class=\"compare-block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-long-description") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":25,"column":47}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-long-description") : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":40},"end":{"line":32,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"link-1-url") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":40},"end":{"line":42,"column":47}}})) != null ? stack1 : "")
    + "                                    </div>\n\n                                    <div class=\"compare-block wp-pricing-compare\">\n                                        <div class=\"text section\">\n                                            <div class=\"wd-mt-0 wd-mb-16\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-replacer") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":48},"end":{"line":53,"column":55}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-replacer") : stack1),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":48},"end":{"line":57,"column":59}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-price-after") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":48},"end":{"line":62,"column":55}}})) != null ? stack1 : "")
    + "                                            </div>\n                                        </div>\n                                    </div>\n\n                                    <div class=\"compare-block\">\n                                        <div class=\"heading section\">\n                                            <h2 class=\"wd-font-19 wd-uppercase wd-color-gray-25\">\n                                                Use for:\n                                            </h2>\n                                        </div>\n                                        <div class=\"text section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-use-for") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":40},"end":{"line":77,"column":47}}})) != null ? stack1 : "")
    + "                                        </div>\n                                    </div>\n\n                                    <div class=\"compare-block\">\n                                        <div class=\"heading section\">\n                                            <h2 class=\"wd-font-19 wd-uppercase wd-color-gray-25\">\n                                                What it does:\n                                            </h2>\n                                        </div>\n                                        <div class=\"text section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-what-it-does") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":44},"end":{"line":92,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-link-url") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":44},"end":{"line":99,"column":51}}})) != null ? stack1 : "")
    + "                                        </div>\n                                    </div>\n                                    <div class=\"compare-block\">\n                                        <div class=\"heading section\">\n                                            <h2 class=\"wd-font-19 wd-uppercase wd-color-gray-25\">\n                                                Includes:\n                                            </h2>\n                                        </div>\n                                        <div class=\"text section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-includes") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":44},"end":{"line":113,"column":51}}})) != null ? stack1 : "")
    + "                                        </div>\n                                    </div>\n                                    <div class=\"compare-block\">\n                                        \n                                \n                                <div class=\"wp-platform\">\n                        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"platform-text") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":36},"end":{"line":130,"column":47}}})) != null ? stack1 : "")
    + "                                </div>\n                                    </div>\n\n                                    <div class=\"compare-block\">\n                                        <div class=\"heading section\">\n                                            <h2 class=\"wd-font-19 wd-uppercase wd-color-gray-25\">\n                                                Licensing:\n                                            </h2>\n                                        </div>\n                                        <div class=\"text section\">\n                                            <ul class=\"wd-pl-20 wd-mt-8\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"compare-licensing") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":48},"end":{"line":144,"column":55}}})) != null ? stack1 : "")
    + "                                            </ul>\n                                        </div>\n                                    </div>\n                                    <div class=\"compare-block wp-compare-button\">\n                                        <div class=\"responsive-grid section\">\n                                            <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"free-trial-cta-link") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":48},"end":{"line":162,"column":55}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cardData") : depth0)) != null ? lookupProperty(stack1,"subscribe-cta-link") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":48},"end":{"line":173,"column":55}}})) != null ? stack1 : "")
    + "                                            </div>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</li>\n";
},"useData":true});